@keyframes fadeIn {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    display: block;
    opacity: 1;
  }

  1% {
    display: none;
    opacity: 1;
  }

  100% {
    display: none;
    opacity: 0;
  }
}

@-webkit-keyframes bounce {
  0% {
    transform: translateY(-5px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-5px);
  }
}

@keyframes bounce {
  0% {
    transform: translateY(-5px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-5px);
  }
}
