@forward 'fonts';
@forward 'mixins';
@forward 'colors';
@forward 'variables';
@forward 'zIndexes';
@forward 'animations';

.root-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slick-slide {
  padding: 0 4vw !important;
  box-sizing: border-box;
}

.ReactModal__Content {
  padding: 0 !important;
  position: fixed !important;
  border-radius: 0 !important;
  z-index: 99;
}

.select {
  width: 100%;
  font-family: 'Noto Sansf KR', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;

  color: #444;
  background-color: #fff;

  padding: 0.6em 1.4em 0.5em 0.8em;
  margin: 0;

  border: 1px solid #aaa;
  border-radius: 0.5em;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
}

.textarea {
  width: 100%;
  height: 80px;
  padding: 8px;
  border-radius: 8px;
}
