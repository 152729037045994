@use "@/assets/styles/global" as global;

.container {
  max-width: 1920px;
  width: 100%;
  height: 100%;
  padding: 40px 100px 100px;
  display: flex;
  gap: 30px;

  article {
    cursor: pointer !important;
    border-radius: 24px;
    display: flex;
    flex-direction: column;

    span {
      color: global.$white;
    }

    h2 {
      color: global.$white;
    }
  }

  &__left {
    display: flex;
    width: 44%;

    article {
      background: #111732 url("~@/assets/images/main_background.png") 50% 50% no-repeat;
      background-size: cover;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      border-radius: 24px;
      padding: 70px;
      gap: 40px;

      div {
        display: flex;
        flex-direction: column;
        gap: 40px;

        h2 {
          font-size: 48px;
          line-height: 64px;
        }
      }

      p {
        overflow: hidden;
        width: 100%;
        height: 100%;
        border-radius: 12px;

        iframe {
          width: 100%;
          height: 100%;
          border: none;
        }
      }
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 54%;

    article {
      padding: 50px;
      display: flex;
      gap: 40px;

      div {
        display: flex;
        flex-direction: column;
        gap: 20px;

        h2 {
          font-size: 32px;
          line-height: 44px;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          white-space: pre-wrap;
        }
      }

      p {
        display: flex;
        flex-direction: column;

        span {
          font-size: 20px;
          line-height: 32px;
          color: #bcc5f7;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          white-space: pre-wrap;
        }
      }
    }

    &__top {
      background-image: url("~@/assets/images/main_insight.png");
      background-position: right center;
      background-size: contain;
      background-repeat: no-repeat;
      background-origin: content-box;
      background-color: #576fec;
      display: flex;
      gap: 20px;

      p {
        width: 24.563vw;

        span {
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2 !important;
          white-space: pre-wrap;
        }
      }
    }

    &__bottom {
      display: flex;
      gap: 30px;
      flex: 1;

      article {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 50px;
        gap: 40px;

        &:nth-child(1) {
          background-color: #df1464;

          p {
            span {
              color: #f2a1c1;
            }
          }
        }

        &:nth-child(2) {
          background-color: #4e43be;

          p {
            span {
              color: #a0a8d6;
            }
          }
        }

        div {
          display: flex;
          flex-direction: column;
          gap: 20px;
        }

        p {
          display: flex;
          flex-direction: column;

          span {
            font-size: 20px;
            line-height: 32px;
          }
        }
      }
    }
  }
}

@include global.tablet {
  .container {
    gap: 20px;

    &__left {
      width: 500px;
      height: 462px;

      article {
        padding: 30px 50px;
        gap: 20px;

        div {
          gap: 22px;

          h2 {
            font-size: 36px;
            line-height: 50px;
          }
        }
      }
    }

    &__right {
      display: flex;
      flex-direction: column;
      gap: 20px; //
      width: 560px;

      article {
        padding: 30px 40px;
        display: flex;
        gap: 20px;

        div {
          display: flex;
          flex-direction: column;
          gap: 10px;

          h2 {
            font-size: 24px; //
            line-height: 34px; //
          }
        }

        p {
          span {
            font-size: 16px; //
            line-height: 24px; //
          }
        }
      }

      &__top {
        background-image: url("~@/assets/images/main_insight.png");
        background-position: right center;
        background-size: contain;
        background-repeat: no-repeat;
        background-origin: content-box;
        background-color: #576fec;
        display: flex;
        gap: 20px;
        width: 100%;
        height: 177px;
      }

      &__bottom {
        display: flex;
        gap: 20px;
        flex: 1;

        article {
          display: flex;
          flex-direction: column;
          width: 100%;
          max-width: 270px;
          height: 265px;
          padding: 30px 40px;
          gap: 20px;

          &:nth-child(1) {
            background-color: #df1464;

            p {
              span {
                color: #f2a1c1;
              }
            }
          }

          &:nth-child(2) {
            background-color: #4e43be;

            p {
              span {
                color: #a0a8d6;
              }
            }
          }

          div {
            display: flex;
            flex-direction: column;
            gap: 10px;
          }

          p {
            display: flex;
            flex-direction: column;

            span {
              overflow: hidden;
              font-size: 16px;
              line-height: 24px;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
              white-space: pre-wrap;
            }
          }
        }
      }
    }
  }
}

@include global.mobile {
  .container {
    padding: 24px 24px 50px;
    flex-direction: column;
    gap: 16px;

    article {
      border-radius: 12px;
    }

    &__left {
      width: 100%;
      height: 100%;

      article {
        border-radius: 12px;
        padding: 20px 30px 24px;
        gap: 16px;

        div {
          gap: 10px;

          span {
            font-size: 14px;
            line-height: 20px;
          }

          h2 {
            font-size: 24px;
            line-height: 34px;
          }
        }

        p {
          iframe {
            width: 100%;
            height: 39.444vw;
            border-radius: 12px;
            border: none;
          }
        }
      }
    }

    &__right {
      gap: 16px;
      width: 100%;

      article {
        padding: 30px 30px 40px;
        gap: 10px;

        div {
          display: flex;
          flex-direction: column;
          gap: 10px;

          span {
            font-size: 14px;
            line-height: 20px;
          }

          h2 {
            font-size: 20px;
            line-height: 32px;
          }
        }

        p {
          span {
            font-size: 14px;
            line-height: 24px;
          }
        }
      }

      &__top {
        background-image: unset;

        p {
          width: 100%;
        }
      }

      &__bottom {
        flex-direction: column;
        gap: 16px;

        article {
          padding: 30px 30px 48px;
          gap: 10px;

          div {
            gap: 10px;
          }

          p {
            span {
              font-size: 14px;
              line-height: 24px;
            }
          }
        }
      }
    }
  }
}
