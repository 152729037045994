@use "@/assets/styles/global" as global;

.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: global.$grey800;

  .container {
    max-width: 1920px;
    width: 100%;
    padding: 90px 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: global.$white;

    h2 {
      font-size: 40px;
      line-height: 56px;
    }

    a {
      color: global.$white;
    }

    &__contents {
      display: flex;
      align-items: flex-start;
      gap: 120px;

      div {
        p {
          &:first-child {
            font-size: 16px;
            line-height: 24px;
            font-weight: 600;
            color: global.$grey400;
          }
        }
      }

      &__cs {
        p {
          &:first-child {
            margin-bottom: 20px;
          }

          a {
            color: global.$white;
            font-size: 48px;
            line-height: 64px;
            font-weight: 700;
          }
        }
      }

      &__app {
        p {
          &:first-child {
            margin-bottom: 28px;
          }
          &:last-child {
            display: flex;
            gap: 10px;
            align-items: center;
          }

          button {
            width: 160px;
            height: 46px;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
}

@include global.tablet {
  .wrapper {
    .container {
      padding: 40px 100px;

      h2 {
        font-size: 24px;
        line-height: 34px;
      }

      &__contents {
        gap: 30px;

        div {
          p {
            &:first-child {
              font-size: 14px;
              line-height: 20px;
            }
          }
        }

        &__cs {
          p {
            &:first-child {
              margin-bottom: 5px;
            }

            a {
              font-size: 36px;
              line-height: 50px;
            }
          }
        }

        &__app {
          p {
            &:first-child {
              margin-bottom: 12px;
            }
            &:last-child {
              gap: 8px;
            }

            button {
              width: 121px;
              height: 36px;
            }
          }
        }
      }
    }
  }
}

@include global.mobile {
  .wrapper {
    .container {
      padding: 40px 24px;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;

      h2 {
        font-size: 24px;
        line-height: 34px;
      }

      &__contents {
        flex-direction: column;
        gap: 24px;

        div {
          p {
            &:first-child {
              font-size: 14px;
              line-height: 20px;
            }
          }
        }

        &__cs {
          p {
            &:first-child {
              margin-bottom: 0px;
            }

            a {
              font-size: 32px;
              line-height: 44px;
            }
          }
        }

        &__app {
          p {
            &:first-child {
              margin-bottom: 16px;
            }
            &:last-child {
              flex-direction: column;
              gap: 16px;
            }

            button {
              width: 160px;
              height: 46px;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
}
