/**
 * 공통으로 사용되어야 하는 mixin을 모아놓은 파일입니다.
 */
@mixin flex($justify: center, $align: center, $direction: null) {
  display: flex;
  justify-content: map-get(
    (
      start: flex-start,
      center: center,
      end: flex-end,
      between: space-between,
      even: space-evenly,
      around: space-around,
    ),
    $justify
  );
  align-items: map-get(
    (
      start: flex-start,
      center: center,
      end: flex-end,
    ),
    $align
  );
  @if ($direction) {
    flex-direction: map-get(
      (
        col: column,
        row: row,
        col-r: column-reverse,
        row-r: row-reverse,
      ),
      $direction
    );
  }
}

@mixin ellipsis($lineClamp: 1) {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: floor($lineClamp);
  -webkit-box-orient: vertical;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-overflow: ellipsis;
  word-break: break-word;
  word-wrap: break-word;
}

@mixin content-container-layout {
  padding-top: 70px;
  padding-bottom: 120px;
}

@mixin content-layout {
  width: 1140px;
  margin: 0 auto;
  padding-left: 26px;
  padding-right: 26px;
  box-sizing: content-box;
}

@mixin content-layout-expand {
  @include content-layout;
  width: auto;
  min-width: 1140px;
}

@mixin desktop {
  @media (min-width: 1281px) {
    @content;
  }
}

@mixin content-desktop {
  @media (min-width: 1581px) {
    @content;
  }
}

@mixin content-tablet {
  @media (min-width: 1194px) and (max-width: 1440px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 1194px) and (max-width: 1280px) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: 1193px) {
    @content;
  }
}
