/**
 * 공통으로 사용되어야 하는 컬러 변수를 모아놓은 파일입니다.
 */
$black: #000000;
$staticBlack: #16171b;
$grey800: #1e2025;
$grey700: #444855;
$grey600: #636874;
$grey500: #868c98;
$grey400: #aab0b8;
$grey300: #ced0d7;
$grey200: #dedfe7;
$grey100: #ecedf2;
$grey80: #f0f1f5;
$grey82: #c9cdd7;
$grey70: #f3f3f6;
$grey50: #f7f7f9;
$grey40: #636874;
$white: #ffffff;

$primary900: #122699;
$primary800: #243aad;
$primary700: #2d44b9;
$primary600: #374fc5;
$primary500: #3d57d0;
$primary400: #5d71d8;
$primary300: #7b8cdf;
$primary200: #a2ade8;
$primary100: #c7cdf1;
$primary95: #f1f0ff;
$primary50: #e9ebfa;
$primary20: #051d93;

$primary_01_Disable: #a0a8d6;

$teal900: #005562;
$teal800: #00768e;
$teal700: #008aa7;
$teal600: #009ec1;
$teal500: #07add4;
$teal400: #23b8dd;
$teal300: #4ac4e5;
$teal200: #7ed5ee;
$teal100: #b1e5f6;
$teal50: #e0f5fc;

$secondary400: #576fec;
$secondary95: #ddf5ff;
$secondary50: #00829f;
$secondary_disable: #abb7f6;
$secondary-background: #f3f5fd;
$thirdary95: #fceccd;
$thirdary40: #c18106;

$service_background: #f9f9fc;

$error: #dc0c3e;
$caution: #f1a107;
$decide: #ee6112;
$progress: #287eff;
$success: #11a377;
$fail: #5e6d7e;
