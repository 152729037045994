@use "@/assets/styles/global" as global;

.container {
  max-width: 1920px;
  width: 100%;
  height: 100%;
  padding: 100px;

  &__title {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 0px 70px 64px;
    border-bottom: 1px solid global.$grey800;

    h2 {
      font-size: 52px;
      line-height: 70px;
      color: global.$grey800;
    }

    p {
      span {
        font-size: 24px;
        line-height: 34px;
        color: global.$grey600;
      }
    }
  }
}

.article__container {
  padding-top: 46px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}

.partner__container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 58px;
  row-gap: 96px;
  padding-top: 46px;
}

.keyword__container {
  padding-top: 46px;
  display: flex;
  flex-direction: column;
  gap: 40px;

  &__sentence {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 15px;

    p {
      img {
        width: 66px;
        height: 66px;
      }
    }

    span {
      font-size: 24px;
      line-height: 38px;
      color: global.$grey800;
    }
  }
}

@include global.content-tablet {
  .container {
    padding: 80px 100px;

    &__title {
      gap: 10px;
      padding: 0px 50px 40px;

      h2 {
        font-size: 40px;
        line-height: 56px;
      }

      p {
        span {
          font-size: 20px;
          line-height: 32px;
        }
      }
    }
  }

  .article__container {
    padding-top: 28px;
    gap: 18px;
  }

  .partner__container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 41px;
    row-gap: 46px;
    padding-top: 27px;
  }

  .keyword__container {
    padding-top: 37px;

    &__sentence {
      gap: 10px;
      flex-wrap: wrap;

      p {
        img {
          width: 50px;
          height: 50px;
        }
      }

      span {
        font-size: 1.042vw;
        line-height: 32px;
        color: global.$grey800;
      }
    }
  }
}

@include global.mobile {
  .container {
    width: 100%;
    height: 100%;
    padding: 50px 24px 0px;

    &__title {
      gap: 8px;
      padding: 0px 0px 45px 0px;

      h2 {
        font-size: 32px;
        line-height: 44px;
        text-align: center;
      }

      p {
        display: flex;
        justify-content: center;
        flex-direction: column;

        span {
          font-size: 16px;
          line-height: 24px;
          text-align: center;
        }
      }
    }
  }

  .article__container {
    padding-top: 20px;
    display: block;
    gap: 30px;
    margin: 0 -4vw;
  }

  .partner__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
    row-gap: 31px;
    padding-top: 20px;
  }

  .keyword__container {
    width: 100%;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
    gap: 30px;

    &__sentence {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 8px;

      p {
        img {
          width: 40px;
          height: 40px;
        }
      }

      span {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}
