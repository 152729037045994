@use "@/assets/styles/global" as global;

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 80px;
}

@include global.tablet {
  .container {
    padding-top: 100px;
  }
}

@include global.mobile {
  .container {
    padding-top: 70px;
  }
}
