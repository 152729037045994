@use "@/assets/styles/global" as global;

.wrapper {
  width: 100%;
  background-color: global.$primary900;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  max-width: 1920px;
  width: 100%;
  height: 100%;
  padding: 100px 6.854vw 240px;
  display: flex;
  position: relative;

  article {
    display: flex;
  }

  &__notice {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    &__sticky {
      position: sticky;
      left: 170px;
      top: 100px;
      display: flex;
      flex-direction: column;
      gap: 30vh !important;
    }

    h2 {
      font-size: 52px;
      line-height: 70px;
      color: global.$white;
    }

    div {
      display: flex;
      flex-direction: column;
      gap: 15px;

      h3 {
        font-size: 32px;
        line-height: 44px;
        color: global.$white;
      }

      p {
        display: flex;
        flex-direction: column;
        font-size: 20px;
        line-height: 32px;
        color: global.$primary_01_Disable;
        margin-bottom: 20px;
      }
    }
  }

  &__content {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    // gap: 120px;
    gap: 5.25vw;
  }
}

@include global.tablet {
  .wrapper {
    width: 100%;
    background-color: global.$primary900;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .container {
    padding: 80px 150px 240px;

    article {
      display: flex;
    }

    &__notice {
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      &__sticky {
        position: sticky;
        left: 170px;
        top: 9vh;
        height: 80vh;
        justify-content: space-between;
        display: flex;
        flex-direction: column;
        gap: 25vh !important;
      }

      h2 {
        font-size: 40px;
        line-height: 56px;
        color: global.$white;
      }

      div {
        display: flex;
        flex-direction: column;
        gap: 15px;

        h3 {
          font-size: 24px;
          line-height: 34px;
          color: global.$white;
        }

        p {
          display: flex;
          flex-direction: column;
          font-size: 16px;
          line-height: 24px;
          color: global.$primary_01_Disable;
          margin-bottom: 20px;
        }
      }
    }

    &__content {
      width: 813px;
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
      gap: 80px;
    }
  }
}

@include global.mobile {
  .wrapper {
    overflow: hidden;
    width: 100%;
    background-color: global.$primary900;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .container {
    padding: 50px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h2 {
      color: global.$white;
      padding: 0 24px;
      font-size: 32px;
      line-height: 44px;
      text-align: center;
      margin-bottom: 32px;
    }

    &__content__mobile {
      width: 100%;
    }
  }
}
